import styled from "styled-components";

export const Article = styled.article`
  
  min-width: ${(props) => props.theme.media.skill};

	height: 100%;
  min-height: 100%;
	display: flex;
	flex-direction: column;

  
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => props.theme.fontSize.lg};
  line-height: ${(props) => props.theme.fontSize.lg};
  color: ${(props) => props.theme.colors.darkGray};

  margin: 0px;
`;

export const Header = styled.div<{ height: string }>`
  height: ${(props) => props.height};
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;
  font: normal normal normal 16px/22px IntSchools;
  letter-spacing: 8px;
  color: ${(props) => props.theme.colors.white};

  background-color: ${(props) => props.theme.colors.blue};

  @media screen and (min-width: 767px) {
  }

  :after {
    position: absolute;
    top: ${(props) => props.height};
    left: calc((100% - 200px) / 2);
    height: 2px;
    width: 200px;
    content: "";
    background: ${(props) => props.theme.colors.lightBlue};
    display: block;

    @media screen and (min-width: 767px) {
    }
  }
`;

export const Content = styled.section`

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  width: ${(props) => props.theme.media.skill};
  left: calc((100% - ${(props) => props.theme.media.skill}) / 2);

  @media screen and (min-width: 767px) {
    width: ${(props) => props.theme.media.skill_large};
    left: calc((100% - ${(props) => props.theme.media.skill_large}) / 2);
  }
`;

export const Section = styled.section<{centered?: any}>`
  width: 100%;
  @media screen and (min-width: 767px) {
    text-align: ${(props) => props.centered ? "center" : "left"};
  }
`;

export const SectionPadding = styled.section`
  padding: 20px 0 0 0;

  @media screen and (min-width: 767px) {
    padding: 60px 0 0 0;
  }
`;

export const SectionToken = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionError = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  width: 315px;
`;

export const SectionButtons = styled.section`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionButton = styled.section`
  width: 315px;
  height: 50px;
  letter-spacing: 1.6px;
  font: normal normal normal 16px/22px IntSchools;

  @media screen and (min-width: 767px) {
  }
`;

export const Label = styled.span`
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.darkGray};

  font: normal normal normal 16px/18px JosefinSans;

  > a {
    color: ${(props) => props.theme.colors.darkGray};
    border-bottom: 2px solid #00e0d7;
  }
  > a:hover {
    border-bottom: 2px solid #24A39F;
  }
  > a:visited {
    color: pink;
    border-bottom: 2px solid #939393;
  }
`;

export const LabelError = styled.span`
  text-align: left;
  font: normal normal normal 14px/16px JosefinSans;
  letter-spacing: 0px;
  color: #d1286a;
`;

export const TableRow = styled.div`
  height: 90px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray};

  :first-child {
    border-top: 1px solid ${(props) => props.theme.colors.gray};
  }
`;

export const TableCell = styled.div`
  text-align: left;
  font: normal normal 600 16px/20px JosefinSans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.darkGray};

  padding-left: 20px;

  :first-child {
    padding-left: 0px;
  }
`;

export const Logo = styled.div<{ src: string }>`
  width: 60px;
  height: 60px;

  background: url(${(props) => props.src}) 0 100% no-repeat;
  background-size: 100% 100%;
`;
