var firebase = require("firebase/app");
require("firebase/auth");

// const firebaseConfig = {
//   apiKey: "AIzaSyB9B6ZS_R-lcmG9M7n0q7n74xUw8np2joQ",
//   authDomain: "interactive-schools-calendar.firebaseapp.com",
//   databaseURL: "https://interactive-schools-calendar.firebaseio.com",
//   projectId: "interactive-schools-calendar",
//   storageBucket: "interactive-schools-calendar.appspot.com",
//   messagingSenderId: "97417998807",
//   appId: "1:97417998807:web:85735ba4d6459af2ad5b92",
//   measurementId: "G-MRJ7D0H2JX",
// };

// Initialize Firebase
const firebaseConfig = require("../../serviceAccount.json");
firebase.initializeApp(firebaseConfig);

var UserProfile = (function () {
  var _profile = {
    name: "Administrator",
    authenticated: false,
    onChangeHandlers: new Array<() => void>(),
  };

  var login = function (
    username: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(username, password);
  };

  var setAuthenticated = function (user: any) {
    _profile.name = user.toString();

    _profile.authenticated = true;
    _profile.onChangeHandlers.map((f) => f());
  };

  var logout = function () {
    firebase
      .auth()
      .signOut()
      .then(() => {
        _profile.authenticated = false;
        _profile.onChangeHandlers.map((f) => f());
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  var authenticated = function () {
    return _profile.authenticated;
  };

  var userName = function () {
    return _profile.authenticated ? _profile.name : undefined;
  };

  var addOnChangeHandler = function (onChange: () => void) {
    _profile.onChangeHandlers.push(onChange);
  };

  return {
    login: login,
    logout: logout,
    authenticated: authenticated,
    setAuthenticated: setAuthenticated,
    userName: userName,
    addOnChangeHandler: addOnChangeHandler,
  };
})();

export default UserProfile;
