import React, { useState, useRef, useEffect } from "react";
import history from "../history";

import * as dal from "../../dal/dal";

import PinField from "react-pin-field";
import "./style.css";

import { ButtonIS, ButtonWhite } from "../../assets/styles/basic";
import { Article, Header, Content, SectionToken, SectionPadding, SectionError, SectionButton, Section, Label, LabelError, SectionButtons, TableRow, TableCell, Logo } from "./styled";
import { getClients } from "../../dal/dal";

const SkillLinking: React.FC = () => {
  const [step, setStep] = useState<"token" | "linking">("token");
  const [token, setToken] = useState("");
  const [completed, setCompleted] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [error, setError] = useState(false);

  // //force confirmation screen
  // useEffect(() => {
  //   setCompleted(true);
  //   setToken("QQQQQQ");
  //   onContinue();
  // }, [token]);

  const getParam = (name: string) => {
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results) return results[1];
    else return "";
  };

  const onComplete = () => {
    setCompleted(true);
  };

  const onContinue = () => {
    if (completed === false) return;

    getClients([token]).then((clientSnapshot) => {
      if (clientSnapshot.length > 0) {
        //get the associated tokens
        let client = clientSnapshot[0].data();
        if (client.childTokens.length > 0) {
          getClients(client.childTokens).then((clientsSnapshot) => {
            setClients(clientsSnapshot);
            setError(false);
            setStep("linking");
          });
        } else {
          setClients(clientSnapshot);
          setError(false);
          setStep("linking");
        }
      } else {
        //no client found for the entered token
        setError(true);
        //force red borders
        if (pinFieldsRef !== null) {
          pinFieldsRef.current.forEach((input: any) => {
            input.classList.remove("-success");
            input.classList.add("-error");
          });
        }
      }
    });
  };

  const onOk = () => {
    if (clients.length > 0) {
      if (getParam("redirect_uri") !== "") {
        //redirect
        let url = decodeURIComponent(getParam("redirect_uri")) + "&state=" + getParam("state") + "&access_token=" + token + "&token_type=token";
        window.location.href = url;

        /*
          //increment activations counter
          dal
          .setClientActivations(clients)
          .then((_) => {
            //redirect
            let url = decodeURIComponent(getParam("redirect_uri")) + "&state=" + getParam("state") + "&access_token=" + token + "&token_type=token";
            window.location.href = url;
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
          */
      } 
      else {
        history.push("/");
      }
    }
  };

  const onBack = () => {
    setToken("");
    setStep("token");
  };

  //let pinFieldsRef: any;
  const pinFieldsRef = useRef([] as HTMLInputElement[]);

  useEffect(() => {
    //re-load token code (used when user press BACK button)
    if (pinFieldsRef && pinFieldsRef.current && token) {
      pinFieldsRef.current.forEach((input: any, index: number) => {
        input.value = token[index]  ??  "";
      });
    }
  }); //}, [pinFieldsRef.current]);

  return step === "token" ? (
    <Article>
      <Header height={"120px"}>
        <img src="/img/logo.svg" alt="sparXvoice" width="130px" />
      </Header>
      <Content>
        <Section centered>
          <Label>Please enter the code provided by your school below.</Label>
        </Section>
        <SectionPadding></SectionPadding>
        <SectionToken>
          <PinField ref={pinFieldsRef} className="field-a" onChange={setToken} onComplete={onComplete} format={(k) => k.toUpperCase()} length={6} />
        </SectionToken>
        <SectionError>{error && <LabelError>The code you’ve entered is wrong. Please check and try again.</LabelError>}</SectionError>
        <SectionButton>
          <ButtonIS height={"100%"} width={"100%"} onClick={onContinue} disabled={!completed}>
            submit code
          </ButtonIS>
        </SectionButton>
        <SectionPadding></SectionPadding>
        <Section>
          <Label>
            If you have not been provided with a unique code for your school calendar, please contact your school administrator or email <a href="mailto:voice@interactiveschools.com">voice@interactiveschools.com</a> to request how one can be set-up.
          </Label>
        </Section>
      </Content>
    </Article>
  ) : (
    <Article>
      <Header height={"75px"}>confirmation</Header>
      <Content>
        <Section>
          <Label>You are about to add the following calendar(s) to your voice assistant</Label>
        </Section>
        <SectionPadding></SectionPadding>
        <Section>
          {clients.map((client, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <Logo src={client.data().logoUrl}></Logo>
                </TableCell>
                <TableCell>{client.data().name}</TableCell>
              </TableRow>
            );
          })}
        </Section>
        <SectionPadding></SectionPadding>
        <SectionButtons>
          <ButtonWhite height={"50px"} width="140px" onClick={onBack}>
            BACK
          </ButtonWhite>
          <ButtonIS height={"50px"} width="140px" onClick={onOk}>
            OK
          </ButtonIS>
        </SectionButtons>
      </Content>
    </Article>
  );
};

export default SkillLinking;
