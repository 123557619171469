import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./assets/styles/global";
import { ContentContainer } from "./assets/styles/basic";
import theme from "./assets/styles/theme";

import Navigation from "./components/Navigation";

import history from "./pages/history";

ReactDOM.render(
  <React.Fragment>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ContentContainer>
        <Router history={history}>
          <Navigation />
        </Router>
      </ContentContainer>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
);
