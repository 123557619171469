import styled from "styled-components";

export const AlertWindow = styled.div`
  width: 400px;
  background: #ededed;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(23, 29, 49, 0.08);
  border: 1px solid #939393;

  padding: 20px;
`;

export const AlertTitle = styled.label`
  display: inline-block;
  width: 100%;

  text-align: left;
  font: normal normal normal 16px/22px IntSchools;
  letter-spacing: 8px;
  color: #132622;

  margin-top: 10px;

  text-transform: uppercase;
`;

export const AlertLabel = styled.label`
  display: inline-block;
  width: 100%;

  text-align: left;
  font: normal normal normal 16px/22px JosefinSans;
  color: #132622;

  padding: 10px 0px;
`;

export const AlertButton = styled.div<{ white?: any }>`
  display: inline-block;
  width: 80px;
  height: 40px;

  background: ${(props) => (props.white ? "#FFFFFF" : "#24a39f")};
  border: 1px solid ${(props) => (props.white ? "#D9D9D9" : "#24a39f")};
  border-radius: 5px;

  color: ${(props) => (props.white ? "#132622" : "#FFFFFF")};

  font: normal normal 600 14px/22px JosefinSans;
  letter-spacing: 0.56px;
  text-align: center;
  text-transform: uppercase;

  padding: 10px 0px;
  margin-right: 20px;

  cursor: pointer;
`;
