import styled from "styled-components";

export const Section = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.lg};
  overflow: scroll;

  @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    border-radius: 0;
  }
`;

export const Content = styled.div`
  max-width: ${(props) => props.theme.media.sm};
  height: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 10px;
`;

export const Cell = styled.div`
  width: calc(100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;

  padding: 10px 10px 0px 10px;
`;

export const CellCenter = styled.div`
  width: calc(100%);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
`;

type AlignProps = {
  right?: any;
  nopadding?: any;
  donotexpand?: any;
};
export const CellHalf = styled.div<AlignProps>`
  width: 50%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: ${(props) => (props.right ? "flex-end" : "flex-start")};
  align-content: flex-start;

  padding: ${(props) => (props.nopadding ? "1px" : "10px")};

  @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    width: ${(props) => (props.donotexpand ? "50%" : "100%")};
  }
`;

export const PageTitle = styled.label`
  display: inline-block;
  width: 100%;

  text-align: left;
  font: normal normal normal 25px/30px IntSchools;
  letter-spacing: 2.5px;
  color: ${(props) => props.theme.colors.blue};

  margin-top: 20px;

  text-transform: uppercase;
`;

export const SectionTitle = styled.label`
  display: inline-block;
  width: 100%;

  text-align: left;
  font: normal normal normal 16px/22px IntSchools;
  letter-spacing: 8px;
  color: ${(props) => props.theme.colors.darkGray};

  margin-top: 20px;

  text-transform: uppercase;
`;

export const ErrorText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font: normal normal normal 16px/22px JosefinSans;
  letter-spacing: 0px;
  color: #d1286a;
`;

export const Label = styled.label`
  display: inline-block;

  color: ${(props) => props.theme.colors.darkerGray};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: 11px;
  line-height: 13px;
  padding: 10px 0px;

  text-transform: uppercase;
`;

export const Logo = styled.div<{ src: string }>`
  width: 60px;
  height: 60px;

  background: url(${(props) => props.src}) 0 100% no-repeat;
  background-size: 100% 100%;
`;
