import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none;
    font-family: JosefinSans;
    box-sizing: border-box;
    font-style: normal;
  }

  html, body, #root {
    height: 100%;
    min-height: 450px;
  }

  ul {
    list-style: none;
  }

  a {
    transition: all 0.4s;
  }
`;
