import styled from "styled-components";
import theme from "./theme";

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  padding: 0 16px;
`;

export const ContainerMenu = styled.div`
  max-width: 1280px;
  padding: 0px 18px;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 1280px) {
    padding: 0 16px 0 16px;
  }
`;

export const ContainerMain = styled.div`
  background: ${(props) => props.theme.colors.lighter};
  overflow: hidden;
  @media screen and (min-width: 767px) {
    min-width: 1280px;
    padding-top: 20px;
  }
  padding-top: 8px;
`;

export const Content = styled.div`
  width: calc(100% - 300px);
  padding-right: 26px;

  @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    width: 100%;
    padding-right: 0px;
  }
`;

export const ContentMain = styled.div`
  display: flex;
  flex-flow: wrap;
  padding-bottom: 30px;
`;

export const Sidebar = styled.div`
  width: 300px;

  @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    width: 100%;
    display: none;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

type Button = {
  width?: string;
  height?: string;
  disabled?: boolean;
};

export const Button = styled.div<Button>`
  width: ${(props) => (props.width && `${props.width}`) || "100%"};
  height: ${(props) => (props.height && `${props.height}`) || "40px"};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.xs};
  line-height: 14px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const ButtonIS = styled(Button)`
  text-align: center;
  font: inherit;
  fill: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;

  border-radius: 5px;
  border: 0px;

  background: ${(props) => (props.disabled ? "#939393" : "#24A39F")};

  ${(props) =>
          !props.disabled &&
  `:hover {
    fill: ${props.theme.colors.black};
    color: ${props.theme.colors.black};
    background: #00E0D7;
  }
  `};
`;


export const ButtonWhite = styled(Button)`
  text-align: center;
  font: normal normal normal 16px/22px IntSchools;
  letter-spacing: 1.6px;
  color: ${(props) => props.theme.colors.darkGray};
  text-transform: uppercase;

  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.gray};

  background: ${(props) => props.theme.colors.white};
`;

export const ButtonBlue = styled(Button)`
  font-size: ${(props) => props.theme.fontSize.xl};
`;

export const ButtonRed = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.red};
  background: ${(props) => props.theme.colors.red};
  font-size: ${(props) => props.theme.fontSize.xl};
`;

export const ButtonGray = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.spaceGray};
  background: ${(props) => props.theme.colors.spaceGray};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSize.md};
`;

export const ButtonTransparent = styled(Button)`
  color: ${(props) => props.theme.colors.lighterGray};
  border: 0px transparent;
  background: transparent;
`;

export const ButtonDarkBlue = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  background: ${(props) => props.theme.colors.darkBlue};
`;

export const ButtonTransparentGray = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.lighter};
  color: ${(props) => props.theme.colors.lighter};
  background: transparent;
`;
export const ButtonLightBlue = styled(Button)`
  border: 1px solid #e1e9ff;
  color: ${(props) => props.theme.colors.darkerBlue};
  background: #e1e9ff;
`;

export const ButtonLink = styled(ButtonTransparent)`
  border: 0;
`;

type ButonProps = {
  icon: string;
  text: string;
};

export const ButonIconText = styled.div<ButonProps>`
  display: flex;
  align-items: center;
  position: relative;

  padding-left: 20px;

  :before {
    position: absolute;
    left: 0px;
    width: 20px;
    height: 20px;
    content: "";
    background: url(${(props) => `/img/${props.icon}`}) no-repeat;
  }

  :after {
    content: "${(props) => props.text}";
  }
`;

const getBorderColor = (color?: string): string => {
  switch (color) {
    case "red":
      return "#D1286A";
    case "green":
      return "#19F000"; //theme.colors.green;
    default:
      return theme.colors.lighterGray;
  }
};

const getIcon = (icon?: string): string => {
  switch (icon) {
    case "error":
      return "error.svg";
    case "ok":
      return "ok.svg";
    case "question":
      return "question.svg";
    case "eye_on":
      return "eye_on.svg";
    case "eye_off":
      return "eye_off.svg";
    default:
      return "";
  }
};

type InputProps = {
  label?: string;
  icon?: "error" | "ok" | "eye_on" | "eye_off" | "question";
  border?: "gray" | "red" | "green";
  errorMessage?: string;
  width?: string;
  height?: string;
  disabled?: any;
};

export const Input = styled.div<InputProps>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"};
  position: relative;

  :before {
    content: "${(props) => props.label}";
    white-space: nowrap;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.darkerGray};
    font-weight: ${(props) => props.theme.fontWeight.regular};
    font-size: 11px;
    line-height: 120%;
    margin-left: 4px;
  }

  & > input {
    width: 100%;
    height: 100%;

    ${(props) => props.disabled && "opacity: 0.5"};

    ::placeholder {
      color: ${(props) => `${getBorderColor(props.border)}`};
      opacity: 0.5;
    }

    background: url(${(props) => `./img/${getIcon(props.icon)}`}) no-repeat right center;
    background-position: calc(100% - 16px);
    border-radius: ${(props) => props.theme.borderRadius.sm};
    box-shadow: 0px 2px 10px rgba(23, 29, 49, 0.08);
    border: 1px solid ${(props) => `${getBorderColor(props.border)}`};

    padding: 10px;
  }

  margin-bottom: 24px;

  :after {
    content: "${(props) => props.errorMessage}";
    position: absolute;
    white-space: nowrap;
    color: ${(props) => `${getBorderColor(props.border)}`};
    font-weight: ${(props) => props.theme.fontWeight.regular};
    font-size: ${(props) => props.theme.fontSize.xs};
    line-height: 14px;
    display: block;

    margin-left: 4px;
  }

  @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    height: "40px";
    background-position: calc(100% - 12px);
  }
`;

type Select = {
  width?: String;
  height?: String;
  opened: boolean;
};

export const NavCell = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBubble = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -3px;
  padding: 0 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

interface PlaceholderProps {
  width?: string;
  height?: string;
}

export const Placeholder = styled.div<PlaceholderProps>`
  position: relative;
  top: 10px;
  left: calc((100% - ${(props) => props.width}) / 2);

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  border: 1px solid ${(props) => props.theme.colors.gray};
`;

export const ContentContainer = styled.div`
  height: 100%;

  /* color: ${(props) => props.theme.colors.darkerGray}; */

  /* & > H1 {
    font-size: 40px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 18px !important;
    }
  }

  & > H2 {
    font-size: 32px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 16px !important;
    }
  }

  & > H3 {
    font-size: 28px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 14px !important;
    }
  }

  & > H4 {
    font-size: 18px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 14px !important;
    }
  }

  & > H5 {
    font-size: 16px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 14px !important;
    }
  }

  & > .Caption1 {
    font-size: 14px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};
    text-transform: uppercase;

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 12px !important;
    }
  }

  & > .Caption2 {
    font-size: 12px !important;
    line-height: 120%;
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerGray};
    text-transform: uppercase;

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 12px !important;
      font-weight: normal;
    }
  }

  & > .CaptionField {
    font-size: 11px !important;
    line-height: 120%;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkerGray};
    text-transform: uppercase;

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
    }
  }

  & > .Body1 {
    font-size: 18px !important;
    line-height: 115%;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 14px !important;
      line-height: 120%;
    }
  }

  & > .Body2 {
    font-size: 14px !important;
    line-height: 130%;
    font-weight: normal;
    color: ${(props) => props.theme.colors.darkerGray};

    display: flex;
    align-items: center;

    @media only screen and (max-width: ${(props) => props.theme.media.sm}) {
      font-size: 12px !important;
    }
  } */
`;
