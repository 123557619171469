import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import UserProfile from "../../components/UserProfile";

import history from "../history";
import * as dal from "../../dal/dal";
import { Client } from "../../model/model";
import { Cell, Section, CellHalf, ErrorText, Content, PageTitle, SectionTitle } from "../../assets/styles/components";
import { Table, TableHeader, TableHeaderUl, TableHeaderLi, TableBody, TableRowUl, TableRowLi, Label } from "./table.styled";
import { ButtonIS, ButtonTransparent } from "../../assets/styles/basic";
import { AlertTitle, AlertLabel, AlertButton, AlertWindow } from "./styled";

const Dashboard: React.FC = () => {
  const [clients, setClients] = useState<firebase.firestore.QueryDocumentSnapshot<Client>[]>();
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    if (UserProfile.authenticated() === false) {
      history.push("/");
    }
  }, []);

  //fetch data
  useEffect(() => {
    dal.getClients().then((clientsSnapshot) => setClients(clientsSnapshot));
  }, [refresh]);

  const getClient = (token: string) => {
    return clients?.find((c) => c.data().token === token)?.data();
  };

  const onAdd = () => {
    history.push("/dashboard/clients/new");
  };

  const onEdit = (token: string) => {
    history.push("/dashboard/clients/" + token);
  };

  const onDelete = (token: string) => {
    const client = getClient(token);
    if (client) {
      client.isDeleted = true;
      dal
        .setClient({ ...client })
        .then((_) => {
          setError("");
          setRefresh(client.id);
        })
        .catch((error) => {
          console.error("Error deleting document: ", error);
          setError("Error deleting data: " + error);
        });
    }
  };

  const onDeleteAlert = (token: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertWindow>
            <AlertTitle>Confirm delete</AlertTitle>
            <AlertLabel>Are you sure you want to delete this TOKEN?</AlertLabel>
            <AlertButton white onClick={onClose}>
              no
            </AlertButton>
            <AlertButton
              onClick={() => {
                onDelete(token);
                onClose();
              }}
            >
              ok
            </AlertButton>
          </AlertWindow>
        );
      },
    });
  };

  return (
    <React.Fragment>
      <Section>
        <Content>
          <Cell>
            <PageTitle>Dashboard</PageTitle>
          </Cell>
          <Cell>
            <SectionTitle>Clients</SectionTitle>
          </Cell>
          <Cell>
            <Table>
              <TableHeader>
                <TableHeaderUl>
                  <TableHeaderLi colWidth="50px"></TableHeaderLi>
                  <TableHeaderLi colWidth="200px">name</TableHeaderLi>
                  <TableHeaderLi colWidth="250px">address</TableHeaderLi>
                  <TableHeaderLi colWidth="100px">token</TableHeaderLi>
                  <TableHeaderLi>actions</TableHeaderLi>
                </TableHeaderUl>
              </TableHeader>
              <TableBody>
                {clients?.map((item, index) => {
                  const x = item.data();
                  return (
                    <TableRowUl index={index} key={index}>
                      <TableRowLi>
                        <img src={x.logoUrl} alt={x.logoUrl} width="32px" />
                      </TableRowLi>
                      <TableRowLi colWidth="200px">{x.name}</TableRowLi>
                      <TableRowLi colWidth="250px">{x.address}</TableRowLi>
                      <TableRowLi colWidth="100px">{x.token}</TableRowLi>
                      <TableRowLi>
                        <ButtonTransparent width="20px" onClick={() => onEdit(x.token)}>
                          <img src={"img/pencil.svg"} alt={"edit"} width="12px" />
                        </ButtonTransparent>
                      </TableRowLi>
                      <TableRowLi>
                        <ButtonTransparent width="20px" onClick={() => onDeleteAlert(x.token)}>
                          <img src={"img/bin.svg"} alt={"delete"} width="12px" />
                        </ButtonTransparent>
                      </TableRowLi>
                      <TableRowLi>{x.activations}</TableRowLi>
                    </TableRowUl>
                  );
                })}
              </TableBody>
            </Table>
          </Cell>
          <CellHalf></CellHalf>
          <CellHalf right>
            <ButtonIS width="120px" onClick={onAdd}>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <path d="M25.5,19.5h-6v6h-3v-6h-6v-3h6v-6h3v6h6Z" transform="translate(-10.5 -10.5)" />
              </svg>
              <Label>Add client</Label>
            </ButtonIS>
            <ErrorText>{error}</ErrorText>
          </CellHalf>
        </Content>
      </Section>
    </React.Fragment>
  );
};

export default Dashboard;
