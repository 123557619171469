import React, { useState } from "react";

import { Section, Content, Cell, CellHalf, ErrorText } from "../../assets/styles/components";

import { ButtonIS, Input } from "../../assets/styles/basic";

import UserProfile from "../UserProfile";
import history from "../../pages/history";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tryAgain, setTryAgain] = useState(false);

  const login = () => {
    UserProfile.login(username, password)
      .then((user: any) => {
        UserProfile.setAuthenticated(user);
        history.push("/dashboard");
      })
      .catch((error: any) => {
        setTryAgain(true);
        console.log(error.code);
      });
  };

  return (
    <Section>
      <Content style={{ width: "400px" }}>
        <Cell>
          <Input label={"Username"}>
            <input value={username} onChange={(event) => setUsername(event.target.value)} placeholder="Username" />
          </Input>
        </Cell>
        <Cell></Cell>
        <Cell>
          <Input label={"Password"}>
            <input type={"password"} value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password" />
          </Input>
        </Cell>
        <Cell></Cell>
        <CellHalf>
          <ErrorText>{tryAgain ? "Try again!" : ""}</ErrorText>
        </CellHalf>
        <CellHalf>
          <ButtonIS onClick={login}>Login</ButtonIS>
        </CellHalf>
      </Content>
    </Section>
  );
};

export default Login;
