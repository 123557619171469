import React from "react";
import UserProfile from "../../components/UserProfile";
import Login from "../../components/Login";

const Home: React.FC = () => {
  return (
    <React.Fragment>
      {UserProfile.authenticated() === false ? (
        <React.Fragment>
          <Login></Login>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
