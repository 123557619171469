import styled from "styled-components";

export const Table = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
`;

export const TableHeader = styled.div``;

export const TableHeaderUl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 50px;

  background-color: ${(props) => props.theme.colors.white};
  border-top: 1px solid ${(props) => props.theme.colors.gray};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};

  overflow-x: hidden;
`;

interface TableHeaderLiProps {
  colWidth?: string;
}

export const TableHeaderLi = styled.div<TableHeaderLiProps>`
  width: ${(props) => props.colWidth};
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  text-align: left;
  font: normal normal normal 14px/22px IntSchools;
  letter-spacing: 1.4px;
  color: ${(props) => props.theme.colors.darkGray};

  text-transform: uppercase;

  overflow: hidden;
  padding: 0px 10px;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface TableRowProps {
  index?: number;
}

export const TableRowUl = styled.div<TableRowProps>`
  width: 100%;
  min-height: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  overflow: hidden;

  background-color: ${(props) => (props.index && props.index % 2 ? props.theme.colors.white : props.theme.colors.lightGray)};

  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  }
`;

export const TableRowLi = styled.div<TableHeaderLiProps>`
  width: ${(props) => props.colWidth};

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  text-align: left;
  font: normal normal normal 16px/22px JosefinSans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.darkGray};

  overflow: hidden;

  padding: 0px 10px;

  svg:hover {
    fill: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;

export const Label = styled.span`
  text-align: left;
  font: normal normal 500 14px/22px JosefinSans;
  letter-spacing: 0.56px;
  /* color: ${(props) => props.theme.colors.white}; */
  text-transform: none;
  padding-left: 10px;
`;
