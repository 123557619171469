import * as firebase from "firebase/app";

export interface IEntity {
  id?: string;
}

export interface IClient extends IEntity {
  name: string;
  address: string;
  token: string;
  childTokens: string[];
  logoUrl: string;
  calendar: ICalendar;
  isDeleted: boolean;
  createdDate: firebase.firestore.Timestamp;
  activations: number;
}

export interface ICalendar {
  hash: string;
  lastUpdate: firebase.firestore.Timestamp;
  url: string;
}

export class Client implements IClient {
  id: string;
  name: string;
  address: string;
  token: string;
  childTokens: string[];
  logoUrl: string;
  calendar: ICalendar;
  isDeleted: boolean;
  createdDate: firebase.firestore.Timestamp;
  activations: number;

constructor(
    id: string,
    name: string,
    address: string,
    token: string,
    childTokens: string[],
    logoUrl: string,
    calendar: ICalendar,
    isDeleted: boolean, 
    createdDate: firebase.firestore.Timestamp,
    activations: number
  ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.token = token;
    this.childTokens = childTokens;
    this.logoUrl = logoUrl;
    this.calendar = calendar;
    this.isDeleted = isDeleted;
    this.createdDate = createdDate;
    this.activations  = activations;
  }
  toString() {
    return this.id;
  }

  static newClient() {
    return new Client(
      "",
      "",
      "",
      "",
      [],
      "",
      {
        hash: "",
        lastUpdate: new firebase.firestore.Timestamp(0, 0),
        url: "",
      },
      false,
      firebase.firestore.Timestamp.now(),
      0
    );
  }
}

// Firestore data converter
export const clientConverter = {
  toFirestore: function (obj: Client) {
    return {
      id: obj.id,
      name: obj.name,
      address: obj.address,
      token: obj.token,
      childTokens: obj.childTokens??[],
      logoUrl: obj.logoUrl,
      calendar: {
        hash: obj.calendar.hash,
        lastUpdate: obj.calendar.lastUpdate,
        url: obj.calendar.url,
      },
      isDeleted: obj.isDeleted,
      createdDate: obj.createdDate,
      activations: obj.activations,
    };
  },
  fromFirestore: function (snapshot: any) {
    let item = snapshot.data ? snapshot.data() : snapshot;
    return new Client(
      snapshot.id,
      item.name,
      item.address,
      item.token,
      item.childTokens??[],
      item.logoUrl,
      {
        hash: item.calendar["hash"],
        lastUpdate: item.calendar["lastUpdate"],
        url: item.calendar["url"],
      },
      item.isDeleted,
      item.createdDate,
      item.activations
    );
  },
};
