import React, { useState, useEffect } from "react";
import { useLocation, Router, Switch, Route } from "react-router-dom";
import history from "../../pages/history";

import About from "../../pages/about";
import Home from "../../pages/home";
import Dashboard from "../../pages/dashboard";
import ClientPage from "../../pages/dashboard/clients";
import SkillLinking from "../../pages/skill/linking";
import SkillTesting from "../../pages/skill/testing";

import UserProfile from "../../components/UserProfile";

import { NavBar, NavLink, NavBarLeft, NavBarCenter, NavBarRight, Logo } from "./styled";

const Navigation = () => {
  const reload = () => window.location.reload();
  const logout = () => UserProfile.logout();

  const [activePage, setActivePage] = useState("home");

  const [authenticated, setAuthenticated] = useState(UserProfile.authenticated());

  useEffect(
    () =>
      UserProfile.addOnChangeHandler(() => {
        setAuthenticated(UserProfile.authenticated());
        if (!UserProfile.authenticated()) history.push("/");
      }),
    []
  );

  const loc = useLocation();

  useEffect(() => setActivePage(loc.pathname), [loc]);

  return (
    <React.Fragment>
      {loc.pathname !== "/skill" ? (
        <NavBar>
          <NavBarLeft>
            <NavLink to="/home">
              <Logo></Logo>
            </NavLink>
          </NavBarLeft>
          <NavBarCenter>
            {authenticated ? (
              <React.Fragment>
                <NavLink to="/dashboard" isActive={activePage === "/dashboard"}>
                  <p>Dashboard</p>
                </NavLink>
                <NavLink to="/skill-testing" isActive={activePage === "/skill-testing"}>
                  <p>Skill Linking</p>
                </NavLink>
                <NavLink to="/skill" isActive={activePage === "/skill"}>
                  <p>Skill</p>
                </NavLink>
                <NavLink to="/about" isActive={activePage === "/about"}>
                  <p>About</p>
                </NavLink>
                <NavLink to="/terms.html" target="_blank">
                  <p>Terms</p>
                </NavLink>
                <NavLink to="/privacy.html" target="_blank">
                  <p>Privacy</p>
                </NavLink>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </NavBarCenter>
          <NavBarRight>
            {authenticated ? (
              <React.Fragment>
                <NavLink to="/" hasBorder={true} onClick={logout}>
                  <p>Logout</p>
                </NavLink>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </NavBarRight>
        </NavBar>
      ) : (
        <React.Fragment />
      )}
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/clients/:token" exact component={ClientPage} />
          <Route path="/skill-testing" exact component={SkillTesting} />
          <Route path="/skill" exact component={SkillLinking} />
          <Route path="/about" exact component={About} />
          <Route path="/terms.html" onEnter={reload} />
          <Route path="/privacy.html" onEnter={reload} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Navigation;
