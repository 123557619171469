import { clientConverter, Client } from "../model/model";

var firebase = require("firebase/app");
require("firebase/firestore");

/**
 * DB instance
 */

//firebase.firestore.Firestore
const _db = firebase.firestore() as firebase.firestore.Firestore;

export const getClients = async function (tokens?: string[]): Promise<firebase.firestore.QueryDocumentSnapshot<Client>[]> {
  const clientsRef =
 
      tokens  &&  tokens.length > 0
        ? await _db.collection("clients").withConverter<Client>(clientConverter).where("token", "in", tokens).where("isDeleted", "==", false).get()
        : await _db.collection("clients").withConverter<Client>(clientConverter).where("isDeleted", "==", false).orderBy("createdDate", "asc").get();

  //clientsRef.forEach((x) => console.log(x.data()));

  return clientsRef.docs;
};

export const setClient = async function (client: Client): Promise<any> {
  if (client.id === "") {
    const clientsRef = await _db.collection("clients").where("token", "==", client.token).get();
    if (clientsRef.docs.length) {
      throw new Error("TOKEN was used before. Please enter a differerent one!");
    }
    return _db.collection("clients").add(client);
  } else {
    return _db.collection("clients").doc(client.id).set(client);
  }
};


export const setClientActivations = async function (clients: any[]): Promise<any> {
  var batch = _db.batch();

  clients.forEach((client) => {
    let c = client.data();
    var ref = _db.collection("clients").doc(client.id);
    if (c.activations) {
      batch.update(ref, { activations: c.activations + 1 });
    } else {
      batch.update(ref, { activations: 1 });
    }
  });

  return batch.commit();
};
