import React from "react";
import { Placeholder } from "../../assets/styles/basic";
import SkillLinking from "./linking";

const SkillTesting: React.FC = () => {
  return (
    <Placeholder width={"400px"} height={"600px"}>
      <SkillLinking></SkillLinking>
    </Placeholder>
  );
};

export default SkillTesting;
