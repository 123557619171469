import React, { useEffect } from "react";
import UserProfile from "../../components/UserProfile";

import { Section, Content, Cell } from "../../assets/styles/components";

import history from "../history";

const About: React.FC = () => {
  useEffect(() => {
    if (UserProfile.authenticated() === false) {
      history.push("/");
    }
  }, []);

  return (
    <Section>
      <Content>
        <Cell>Interactive Schools - Calendar Admin - 2020</Cell>
      </Content>
    </Section>
  );
};

export default About;
