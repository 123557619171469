import React, { useEffect, useState } from "react";
import UserProfile from "../../../components/UserProfile";
import history from "../../history";
import { useParams } from "react-router-dom";
import * as dal from "../../../dal/dal";
import { Client } from "../../../model/model";
import { Section, Content, Cell, CellCenter, ErrorText, PageTitle, Logo } from "../../../assets/styles/components";
import { Input, ButtonIS, ButtonWhite } from "../../../assets/styles/basic";

const ClientPage: React.FC = (props) => {
  const defaultLogoUrl = `${process.env.REACT_APP_PUBLIC_URL}/img/is.png`;

  const [client, setClient] = useState<Client>(Client.newClient());
  const [clients, setClients] = useState<firebase.firestore.QueryDocumentSnapshot<Client>[]>();
  const [childTokens, setChildTokens] = useState("");
  const [addChildTokens, setAddChildTokens] = useState(false);
  const [error, setError] = useState("");
  const [errorToken, setErrorToken] = useState("");
  const [errorChildTokens, setErrorChildTokens] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorUrl, setErrorUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState(defaultLogoUrl);

  const GsdLogoUrl = (url?: string) => {
    return url?.substr(0, url.indexOf('/media/')) + '/gsd-logo.png'; 
  }

  useEffect(() => {
    if (UserProfile.authenticated() === false) {
      history.push("/");
    }
  }, []);

  let { token } = useParams<{token:string}>();

  //fetch all clients 
  useEffect(() => {
    dal.getClients().then((clientsSnapshot) => setClients(clientsSnapshot));
  }, []);

  //fetch current client
  useEffect(() => {
    if (token !== "new") {
      dal.getClients([token]).then((clientsSnapshot) => {
        if (clientsSnapshot.length > 0) {
          setClient(clientsSnapshot[0].data());
          setChildTokens(clientsSnapshot[0].data().childTokens.join(";"));
          setAddChildTokens(clientsSnapshot[0].data().childTokens.length > 0);
        } else {
          setError("Fail to load client data");
        }
      });
    }
  }, [token]);

  const onSave = () => {
    setError("");
    let hasErrors = false;

    //validation
    if (onValidateChildTokens(childTokens, addChildTokens) === false){
      setErrorChildTokens("TOKEN invalid! Choose an existing token associated to a school.");
      setError("Validation errors!");      
      hasErrors = true;
    }
    if (client?.token.length !== 6) {
      setErrorToken("TOKEN length must be 6!");
      setError("Validation errors!");
      hasErrors = true;
    }
    if (client?.name === "") {
      setErrorName("NAME should not be empty!");
      setError("Validation errors!");
      hasErrors = true;
    }
    if (client?.calendar.url === "" && childTokens === "") {
      setErrorUrl("URL should not be empty!");
      setError("Validation errors!");
      hasErrors = true;
    }
    if (client?.logoUrl === "") {
      client.logoUrl = logoUrl;
    }
    client.token = client.token.toUpperCase();

    if (hasErrors) return;

    //console.log(client);
    //save data
    dal
      .setClient({...client})
      .then((_) => {
        setError("");
        history.push("/dashboard");
      })
      .catch((error) => {
        console.error("Error: ", error);
        setError("Error: " + error);
      });
  };

  const onBack = () => {
    setError("");
    history.push("/dashboard");
  };

  const randomToken = () => {
    var token = "";
    var characters = process.env.REACT_APP_TOKEN_CHARACTERS ?? "ABCD01248"; //"ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      token += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    let c = { ...client } as Client;
    c.token = token;
    setClient(c);

    return token;
  };

  const onValidateChildTokens = (s:string, b:boolean) => {
    let hasErrors = false;
    setErrorChildTokens("");
    setError("");
  
    if (b === false) {
      return !hasErrors;
    }

    let arr = s.toUpperCase().split(/[\s,; ]+/).filter(i => i !== "");
    let names = "";
    arr.map((t) => {
      let found = clients?.find((c) => c.data().token === t && c.data().childTokens.length === 0)?.data();
      if (found) {
        names += found.name + "; ";

        let c = { ...client } as Client;
        c.name = names;
        c.address = "+";
        c.calendar.url = "";
        c.childTokens = arr;
        c.logoUrl = defaultLogoUrl;
        setClient(c);
      } else {
        setErrorChildTokens("TOKEN invalid! Choose an existing token associated to a school.");
        setError("Validation errors!");
        hasErrors = true;
      }
      return true;
    });

    return !hasErrors;
  };

  return (
    <Section>
      <Content style={{ width: "600px" }}>
        <Cell>
          <PageTitle>Client</PageTitle>
        </Cell>
        <Cell>
          <Input label={"TOKEN"} errorMessage={errorToken} border={errorToken ? "red" : undefined}>
            <input
              style={{ textTransform: "uppercase" }}
              value={client?.token || randomToken()}
              onChange={(e) => {
                let c = { ...client } as Client;
                c.token = e.target.value;
                setClient(c);
              }}
              placeholder="token"
            />
          </Input>
        </Cell>
        <Cell>
          <Input label={"add child tokens"} width={"20px"} height={"20px"} >
            <input
              type="checkbox"
              checked={addChildTokens}
              style={{ textTransform: "uppercase" }}
              onChange={(e) => {
                setAddChildTokens(e.target.checked);
                onValidateChildTokens(childTokens, e.target.checked);
              }}
            />
            
          </Input>
        </Cell>
        <Cell>
          <Input label={"CHILD TOKENS"} disabled={!addChildTokens} errorMessage={errorChildTokens} border={errorChildTokens ? "red" : undefined}>
            <input
              readOnly={!addChildTokens}
              style={{ textTransform: "uppercase" }}
              value={childTokens}
              onChange={(e) => {
                setChildTokens(e.target.value);
                onValidateChildTokens(e.target.value, addChildTokens);
              }}
              placeholder="tokens"
            />
          </Input>
        </Cell>
        <Cell>
          <Input label={"Name"} disabled={addChildTokens} errorMessage={errorName} border={errorName ? "red" : undefined}>
            <input
              readOnly={addChildTokens}
              value={client?.name || ""}
              onChange={(e) => {
                let c = { ...client } as Client;
                c.name = e.target.value;
                setClient(c);
              }}
              placeholder="Name"
            />
          </Input>
        </Cell>
        <Cell>
          <Input label={"Calendar URL"} disabled={addChildTokens} errorMessage={errorUrl} border={errorUrl ? "red" : undefined}>
            <input
              readOnly={addChildTokens}
              value={client?.calendar.url || ""}
              onChange={(e) => {
                let c = { ...client } as Client;
                c.calendar.url = e.target.value;
                setClient(c);
                setLogoUrl(GsdLogoUrl(c.calendar.url));
              }}
              placeholder="Calendar URL"
            />
          </Input>
        </Cell>
        <Cell>
          <Input label={"Address"} disabled={addChildTokens}>
            <input
              readOnly={addChildTokens}
              value={client?.address || ""}
              onChange={(e) => {
                let c = { ...client } as Client;
                c.address = e.target.value;
                setClient(c);
              }}
              placeholder="Address"
            />
          </Input>
        </Cell>
        <Cell>
          <Input label={"Logo URL"} disabled={addChildTokens}>
            <input
              readOnly={addChildTokens}
              value={client?.logoUrl || logoUrl}
              onChange={(e) => {
                let c = { ...client } as Client;
                c.logoUrl = e.target.value;
                setClient(c);
              }}
              placeholder="Logo URL"
            />
          </Input>
        </Cell>
        <Cell>
          <Logo src={client?.logoUrl || logoUrl}></Logo>
        </Cell>
        <Cell>
          <ErrorText>{error}</ErrorText>
        </Cell>
        <CellCenter>
          <ButtonWhite height={"50px"} width="140px" onClick={onBack}>
            BACK
          </ButtonWhite>
          <ButtonIS height={"50px"} width="140px" onClick={onSave}>
            OK
          </ButtonIS>
        </CellCenter>
      </Content>
    </Section>
  );
};

export default ClientPage;
