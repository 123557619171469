import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 60px;

  background-color: ${(props) => props.theme.colors.blue};

  @media screen and (max-width: ${(props) => props.theme.media.sm}) {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const NavBarLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const NavBarCenter = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;

export const NavBarRight = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
`;

export const Logo = styled.div`
  /* float: left; */
  width: 46px;
  height: 46px;
  background: transparent url("img/logo_is.svg") 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export interface ActiveProps {
  isActive?: boolean;
  hasBorder?: boolean;
}

export const NavLink = styled(Link)<ActiveProps>`
  padding: 0 20px;

  > p {
    height: 40px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    cursor: pointer;

    font: normal normal normal 14px/22px IntSchools;
    letter-spacing: 1.4px;

    color: ${(props) => props.theme.colors.white};

    text-transform: uppercase;
    text-decoration: none;

    :hover {
      opacity: 0.8;
    }
  }

  > p {
    :after {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      content: '';
      width: 100%;
      height: 5px;
      background: ${(props) => (props.isActive ? props.theme.colors.white : "invisible")};
      -webkit-border-top-left-radius: 2px;
      -webkit-border-top-right-radius: 2px;
      -moz-border-radius-topleft: 2px;
      -moz-border-radius-topright: 2px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;    
      }
      :hover {
        opacity: 1;
      }
      :hover:after {
        ${(props) => !props.isActive && !props.hasBorder && `background: ${props.theme.colors.lightBlue}`};
    }
  }



  ${(props) =>
    props.hasBorder &&
    `
> p {
  border: 1px solid ${props.theme.colors.gray};
  border-radius: 5px;
  :hover {
    color: ${props.theme.colors.black};
    background: ${props.theme.colors.lightBlue};
    border: 1px solid ${props.theme.colors.lightBlue};
  }
}
`}

@media screen and (max-width: ${(props) => props.theme.media.sm}) {
    padding: 0 3px;
  }
`;
