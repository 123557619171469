type Theme = {
  colors: {
    lightBlue: string;
    darkBlue: string;
    darkPurple: string;
    lightPurple: string;
    lightGreen: string;
    grayBlue: string;
    spaceGray: string;
    darkerGray: string;
    lighter: string;
    darkerBlue: string;
    IS: string;

    black: string;
    white: string;
    lighterGray: string;
    lightGray: string;
    gray: string;
    darkGray: string;
    green: string;
    blue: string;
    red: string;
  };
  fontSize: {
    xxs: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    xxxl: string;
    xxxxl: string;
  };
  fontWeight: {
    light: string;
    regular: string;
    medium: string;
    bold: string;
    black: string;
  };
  borderRadius: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
  };
  width: {
    xxs: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
  media: {
    skill: string;
    skill_large: string;
    xxs: string;
    xs: string;
    sm: string;
    md: string;
  };
};

const theme: Theme = {
  colors: {
    darkerBlue: "#0F2369",
    darkBlue: "#0D2369",
    darkPurple: "#000A3C",
    lightPurple: "#5167AD",
    lightGreen: "#19F000",
    spaceGray: "#97999F",
    darkerGray: "#1A1D24",
    grayBlue: "#8D99AE",
    lighter: "#F6F7F9",
    black: "#000000",
    lighterGray: "#D9D9D9",
    red: "#C30303",
    green: "#30BD00",
    IS: "#81D8CF",

    white: "#FFFFFF",
    blue: "#24A39F",
    darkGray: "#132622",
    gray: "#D9D9D9",
    lightGray: "#F8F9FD",
    lightBlue: "#00E0D7",
  },
  fontSize: {
    xxs: "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "24px",
    xxl: "26px",
    xxxl: "32px",
    xxxxl: "40px",
  },
  fontWeight: {
    light: "300",
    regular: "400",
    medium: "500",
    bold: "700",
    black: "900",
  },
  borderRadius: {
    xs: "3px",
    sm: "6px",
    md: "8px",
    lg: "10px",
  },
  width: {
    xxs: "192px",
    xs: "260px",
    sm: "300px",
    md: "519px",
    lg: "627px",
    xl: "954px",
    xxl: "1280px",
  },
  media: {
    skill: "315px",
    skill_large: "500px",
    xxs: "315px",
    xs: "658px",
    sm: "767px",
    md: "1024px",
  },
};

export default theme;
